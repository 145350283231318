import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProject,
  createComment,
  getComments,
  deleteProject,
} from "../services/api";
import { useAuth } from "../contexts/AuthContext";
import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import "../styles/ContentStyles.css";

function ProjectDetail() {
  const [project, setProject] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, isAuthorized } = useAuth();

  useEffect(() => {
    fetchProject();
    fetchComments();
  }, [id]);

  const fetchProject = async () => {
    try {
      const response = await getProject(id);
      setProject(response.data);
    } catch (error) {
      console.error("Failed to fetch project:", error);
    }
  };
  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const fetchComments = async () => {
    try {
      const response = await getComments(id, "project");
      setComments(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      await createComment(id, "project", { content: comment });
      setComment("");
      fetchComments(); // 댓글 목록 새로고침
    } catch (error) {
      console.error("Failed to submit comment:", error);
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this project?"
    );
    if (!confirmed) return;

    try {
      await deleteProject(id);
      alert("Project deleted successfully.");
      navigate("/projects");
    } catch (error) {
      console.error("Failed to delete project:", error);
      alert("Failed to delete project. Please try again.");
    }
  };

  if (!project) return <div>Loading...</div>;

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#111418] text-3xl font-bold leading-tight">
            {project.title}
          </h1>
          {token && isAuthorized && (
            <div className="flex gap-2">
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#f0f2f4] text-[#111418] text-sm font-bold leading-normal tracking-[0.015em]"
                onClick={() =>
                  navigate(`/editor/edit/${project.id}?type=project`)
                }
              >
                <span className="truncate">Edit Project</span>
              </button>
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-red-600 text-white text-sm font-bold leading-normal tracking-[0.015em]"
                onClick={handleDelete}
              >
                <span className="truncate">Delete Project</span>
              </button>
            </div>
          )}
        </div>

        {project.image && (
          <div className="w-full h-96 mb-8">
            <img
              className="w-full h-full object-cover rounded-lg shadow-lg"
              src={project.image}
              alt={project.title}
            />
          </div>
        )}

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">
            Description
          </h2>
          <div className="content-area">
            {parse(sanitizeHTML(project.content))}
          </div>
        </div>

        {project.subtitle && (
          <div className="mb-8">
            <h2 className="text-[#111418] text-xl font-semibold mb-2">
              Subtitle
            </h2>
            <p className="text-[#637588] text-base">{project.subtitle}</p>
          </div>
        )}

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">Date</h2>
          <p className="text-[#637588] text-base">
            {new Date(project.date).toLocaleDateString()}
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Comments
          </h2>
          {comments.map((comment) => (
            <div key={comment.id} className="mb-4 p-4 bg-gray-100 rounded">
              <div className="content-area">
                {parse(sanitizeHTML(comment.content))}
              </div>
              <p className="text-sm text-gray-500 mt-2">
                By: {comment.wallet_address} on{" "}
                {new Date(comment.createdAt).toLocaleString()}
              </p>
            </div>
          ))}
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Leave a Comment
          </h2>
          <form onSubmit={handleCommentSubmit}>
            <Editor
              apiKey="bz5ro00mjm6p6u5fgpo6wok5mjhhevw75x973lk7m797620d"
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
              }}
              value={comment}
              onEditorChange={setComment}
            />
            <button
              type="submit"
              className="mt-4 flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#111418] text-white text-sm font-bold leading-normal tracking-[0.015em]"
            >
              Submit Comment
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;
