import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getTechs } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";

function Techs() {
  const [techs, setTechs] = useState([]);
  const { token, isAuthorized } = useAuth();
  const navigate = useNavigate();
  const DEFAULT_IMAGE = "/basicImage.webp";

  useEffect(() => {
    const fetchTechs = async () => {
      try {
        const response = await getTechs();
        console.log("Fetched tech blogs:", response.data);
        setTechs(response.data);
      } catch (error) {
        console.error("Failed to fetch tech blogs:", error);
      }
    };

    fetchTechs();
  }, []);

  const getImageUrl = (tech) => {
    return tech.image || DEFAULT_IMAGE;
  };

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-[#111418] text-3xl font-bold leading-tight mb-2">
              Tech Blog
            </h1>
            <p className="text-[#637588] text-sm font-normal leading-normal">
              Latest tech articles and insights
            </p>
          </div>
          {token && isAuthorized && (
            <button
              className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#f0f2f4] text-[#111418] text-sm font-bold leading-normal tracking-[0.015em]"
              onClick={() => navigate("/editor/new?type=tech")}
            >
              <span className="truncate">Create new tech post</span>
            </button>
          )}
        </div>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-6">
          {techs.map((tech) => (
            <div
              key={tech.id}
              className="flex flex-col gap-3 pb-3 cursor-pointer"
              onClick={() => navigate(`/techs/${tech.id}`)}
            >
              <div className="aspect-video rounded-xl overflow-hidden">
                <img
                  src={getImageUrl(tech)}
                  alt={tech.title}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    console.error("Image load error:", e.target.src);
                    e.target.onerror = null;
                    e.target.src = DEFAULT_IMAGE;
                  }}
                />
              </div>
              <div>
                <p className="text-[#111418] text-lg font-medium leading-normal">
                  {tech.title}
                </p>
                <p className="text-[#637588] text-sm font-normal leading-normal mt-1">
                  {tech.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Techs;
