import React, { useState, useEffect } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography as MuiTypography,
  IconButton,
  TextField,
  Button,
  Tooltip,
} from "@mui/material";
import heic2any from "heic2any";
import CloseIcon from "@mui/icons-material/Close";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import CodeIcon from "@mui/icons-material/Code";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ImageIcon from "@mui/icons-material/Image";
import HighlightIcon from "@mui/icons-material/Highlight";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEditor, EditorContent } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import Image from "@tiptap/extension-image";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import { lowlight } from "lowlight";
import css from "highlight.js/lib/languages/css";
import js from "highlight.js/lib/languages/javascript";
import ts from "highlight.js/lib/languages/typescript";
import html from "highlight.js/lib/languages/xml";
import {
  createProject,
  getProject,
  updateProject,
  createArticle,
  getArticle,
  updateArticle,
  createVlog,
  getVlog,
  updateVlog,
  createTech,
  getTech,
  updateTech,
  createPlaylist,
  getPlaylist,
  updatePlaylist,
  uploadImage,
} from "../services/api";
import { useAuth } from "../contexts/AuthContext";
// import "./FullScreenEditor.css";
import "../styles/ContentStyles.css";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import Blockquote from "@tiptap/extension-blockquote";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import History from "@tiptap/extension-history";

lowlight.registerLanguage("html", html);
lowlight.registerLanguage("css", css);
lowlight.registerLanguage("js", js);
lowlight.registerLanguage("ts", ts);

// CustomImage 확장: 이미지 크기 조절 기능 포함
const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent(),
      width: {
        default: "auto",
        renderHTML: (attributes) => ({
          width: attributes.width,
        }),
      },
      height: {
        default: "auto",
        renderHTML: (attributes) => ({
          height: attributes.height,
        }),
      },
    };
  },
  addNodeView() {
    return ({ node, getPos, editor }) => {
      const img = document.createElement("img");
      img.src = node.attrs.src;
      img.style.width = node.attrs.width || "auto";
      img.style.height = node.attrs.height || "auto";

      const onMouseDown = (e) => {
        e.preventDefault();
        const startX = e.pageX;
        const startY = e.pageY;
        const startWidth = img.offsetWidth;
        const startHeight = img.offsetHeight;

        const onMouseMove = (e) => {
          const dx = e.pageX - startX;
          const dy = e.pageY - startY;
          img.style.width = `${startWidth + dx}px`;
          img.style.height = `${startHeight + dy}px`;
        };

        const onMouseUp = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
          editor
            .chain()
            .focus()
            .updateAttributes("image", {
              width: img.style.width,
              height: img.style.height,
            }) // 'setNodeAttribute' 대신 'updateAttributes' 사용
            .run();
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
      };

      img.style.cursor = "nwse-resize";
      img.addEventListener("mousedown", onMouseDown);

      return {
        dom: img,
        update: (updatedNode) => {
          if (updatedNode.attrs.src !== node.attrs.src) {
            img.src = updatedNode.attrs.src;
          }
          if (updatedNode.attrs.width !== node.attrs.width) {
            img.style.width = updatedNode.attrs.width || "auto";
          }
          if (updatedNode.attrs.height !== node.attrs.height) {
            img.style.height = updatedNode.attrs.height || "auto";
          }
          return true;
        },
        destroy: () => {
          img.removeEventListener("mousedown", onMouseDown);
        },
      };
    };
  },
});

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }
  const addImage = () => {
    const url = window.prompt("Image URL");
    if (url) {
      editor.chain().focus().setImage({ src: url, size: "100%" }).run();
    }
  };
  const setImageSize = (size) => {
    const { state, dispatch } = editor;
    const tr = state.tr.setNodeMarkup(
      editor.state.selection.node.attrs.id,
      undefined,
      {
        ...editor.state.selection.node.attrs,
        size,
      }
    );
    dispatch(tr);
  };
  return (
    <div className="editor-menu-bar">
      <Tooltip title="Bold">
        <IconButton
          onClick={() => {
            console.log("Toggling Bold");
            editor.chain().focus().toggleBold().run();
          }}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <FormatBoldIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton
          onClick={() => {
            console.log("Toggling Italic");
            editor.chain().focus().toggleItalic().run();
          }}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <FormatItalicIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Strike">
        <IconButton
          onClick={() => {
            console.log("Toggling Strike");
            editor.chain().focus().toggleStrike().run();
          }}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <FormatStrikethroughIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Code">
        <IconButton
          onClick={() => {
            console.log("Toggling Code");
            editor.chain().focus().toggleCode().run();
          }}
          className={editor.isActive("code") ? "is-active" : ""}
        >
          <CodeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Clear formatting">
        <IconButton
          onClick={() => {
            console.log("Clearing formatting");
            editor.chain().focus().unsetAllMarks().clearNodes().run();
          }}
        >
          <FormatClearIcon />
        </IconButton>
      </Tooltip>
      <div className="divider" />
      <Tooltip title="Heading 1">
        <IconButton
          onClick={() => {
            console.log("Toggling H1");
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          H1
        </IconButton>
      </Tooltip>
      <Tooltip title="Heading 2">
        <IconButton
          onClick={() => {
            console.log("Toggling H2");
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }}
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          H2
        </IconButton>
      </Tooltip>
      <div className="divider" />
      <Tooltip title="Bullet List">
        <IconButton
          onClick={() => {
            console.log("Toggling Bullet List");
            editor.chain().focus().toggleBulletList().run();
          }}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Numbered List">
        <IconButton
          onClick={() => {
            console.log("Toggling Numbered List");
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Task List">
        <IconButton
          onClick={() => {
            console.log("Toggling Task List");
            editor.chain().focus().toggleTaskList().run();
          }}
          className={editor.isActive("taskList") ? "is-active" : ""}
        >
          ☑
        </IconButton>
      </Tooltip>
      <div className="divider" />
      <Tooltip title="Code Block">
        <IconButton
          onClick={() => {
            console.log("Toggling Code Block");
            editor.chain().focus().toggleCodeBlock().run();
          }}
          className={editor.isActive("codeBlock") ? "is-active" : ""}
        >
          <CodeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Blockquote">
        <IconButton
          onClick={() => {
            console.log("Toggling Blockquote");
            editor.chain().focus().toggleBlockquote().run();
          }}
          className={editor.isActive("blockquote") ? "is-active" : ""}
        >
          <FormatQuoteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Horizontal Rule">
        <IconButton
          onClick={() => {
            console.log("Inserting Horizontal Rule");
            editor.chain().focus().setHorizontalRule().run();
          }}
        >
          <HorizontalRuleIcon />
        </IconButton>
      </Tooltip>
      <div className="divider" />
      <Tooltip title="Add Image">
        <IconButton
          onClick={() => {
            console.log("Adding Image");
            addImage();
          }}
        >
          <ImageIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Highlight">
        <IconButton
          onClick={() => {
            console.log("Toggling Highlight");
            editor.chain().focus().toggleHighlight().run();
          }}
          className={editor.isActive("highlight") ? "is-active" : ""}
        >
          <HighlightIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add Link">
        <IconButton
          onClick={() => {
            console.log("Adding Link");
            const url = window.prompt("URL");
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
            }
          }}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove Link">
        <IconButton
          onClick={() => {
            console.log("Removing Link");
            editor.chain().focus().unsetLink().run();
          }}
        >
          <LinkOffIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Left Align">
        <IconButton
          onClick={() => {
            console.log("Aligning Left");
            editor.chain().focus().setTextAlign("left").run();
          }}
          className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
        >
          <FormatAlignLeftIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Center Align">
        <IconButton
          onClick={() => {
            console.log("Aligning Center");
            editor.chain().focus().setTextAlign("center").run();
          }}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
        >
          <FormatAlignCenterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Right Align">
        <IconButton
          onClick={() => {
            console.log("Aligning Right");
            editor.chain().focus().setTextAlign("right").run();
          }}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        >
          <FormatAlignRightIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Justify">
        <IconButton
          onClick={() => {
            console.log("Justifying Text");
            editor.chain().focus().setTextAlign("justify").run();
          }}
          className={
            editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
          }
        >
          <FormatAlignJustifyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Purple Text">
        <IconButton
          onClick={() => {
            console.log("Setting Purple Text");
            editor.chain().focus().setColor("#958DF1").run();
          }}
          className={
            editor.isActive("textStyle", { color: "#958DF1" })
              ? "is-active"
              : ""
          }
        >
          P
        </IconButton>
      </Tooltip>
    </div>
  );
};

const extractFirstImageUrl = (content) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");
  const firstImage = doc.querySelector("img");
  return firstImage ? firstImage.src : null;
};

const FullScreenEditor = ({ mode }) => {
  const [item, setItem] = useState({
    title: "",
    description: "",
    image: "",
  });
  const [content, setContent] = useState("<p>Start writing here...</p>");
  const { address } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type") || "article";
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      Strike,
      Code,
      Heading.configure({
        levels: [1, 2],
      }),
      BulletList,
      OrderedList,
      ListItem,
      CustomImage,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Highlight,
      Typography,
      Link,
      Placeholder.configure({
        placeholder: "Write something …",
      }),
      CodeBlockLowlight.configure({
        lowlight,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      Blockquote,
      HorizontalRule,
      History,
    ],
    content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      console.log("Editor content updated:", html);
      setContent(html);
    },
  });

  const fetchItem = async () => {
    try {
      let response;
      switch (type) {
        case "project":
          response = await getProject(id);
          break;
        case "article":
          response = await getArticle(id);
          break;
        case "vlog":
          response = await getVlog(id);
          break;
        case "tech":
          response = await getTech(id);
          break;
        case "playlist":
          response = await getPlaylist(id);
          break;
        default:
          throw new Error(`Invalid type: ${type}`);
      }
      setItem(response.data);
      if (response.data.content) {
        setContent(response.data.content);
        editor?.commands.setContent(response.data.content); // 크기 정보 포함한 content 설정
      }
    } catch (error) {
      console.error(`Failed to fetch ${type}:`, error);
    }
  };

  useEffect(() => {
    if (mode === "edit" && id) {
      fetchItem();
    }
  }, [mode, id, editor]);

  const handleSaveItem = async () => {
    try {
      const formData = new FormData();
      formData.append("title", item.title);
      formData.append("description", item.description);
      formData.append("content", content); // content에 이미 크기 정보 포함
      formData.append("wallet_address", address);

      let response;
      if (mode === "edit") {
        switch (type) {
          case "project":
            response = await updateProject(id, formData);
            break;
          case "article":
            response = await updateArticle(id, formData);
            break;
          case "vlog":
            response = await updateVlog(id, formData);
            break;
          case "tech":
            response = await updateTech(id, formData);
            break;
          case "playlist":
            response = await updatePlaylist(id, formData);
            break;
          default:
            throw new Error(`Invalid type: ${type}`);
        }
      } else {
        switch (type) {
          case "project":
            response = await createProject(formData);
            break;
          case "article":
            response = await createArticle(formData);
            break;
          case "vlog":
            response = await createVlog(formData);
            break;
          case "tech":
            response = await createTech(formData);
            break;
          case "playlist":
            response = await createPlaylist(formData);
            break;
          default:
            throw new Error(`Invalid type: ${type}`);
        }
      }
      navigate(`/${type}s`);
    } catch (error) {
      console.error(
        `Failed to save ${type}:`,
        error.response?.data || error.message
      );
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    console.log("fullscreenEditor.js file: ", file);

    if (file) {
      let imageUrl = URL.createObjectURL(file); // 이미지 파일을 임시 URL로 변환
      editor.chain().focus().setImage({ src: imageUrl, size: "100%" }).run(); // 미리보기로 에디터에 삽입

      try {
        let imageFile = file;
        // HEIC 파일을 JPEG로 변환
        if (
          file.type === "image/heic" ||
          file.name.toLowerCase().endsWith(".heic")
        ) {
          const blob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8,
          });
          imageFile = new File([blob], file.name.replace(/\.heic$/i, ".jpg"), {
            type: "image/jpeg",
          });
        }
        if (imageFile.type.startsWith("image/")) {
          const formData = new FormData();
          formData.append("file", imageFile, imageFile.name);
          const response = await uploadImage(formData);

          if (response && response.data && response.data.fileUrl) {
            setItem((prevItem) => ({
              ...prevItem,
              image: response.data.fileUrl,
            }));

            // 서버에 업로드된 실제 이미지 URL로 업데이트
            editor
              .chain()
              .focus()
              .setImage({ src: response.data.fileUrl, size: "100%" })
              .run();
          }
        }
      } catch (error) {
        console.error("Error processing or uploading image:", error);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (editor) {
      console.log("Editor is ready", editor);
    }
  }, [editor]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <MuiTypography
            variant="h6"
            sx={{
              flexGrow: 1,
              color: "black",
              fontWeight: "bold",
            }}
          >
            {mode === "edit"
              ? `Edit ${type.charAt(0).toUpperCase() + type.slice(1)}`
              : `New ${type.charAt(0).toUpperCase() + type.slice(1)}`}
          </MuiTypography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => navigate(`/${type}s`)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <TextField
          label="Title"
          value={item.title}
          onChange={(e) => setItem({ ...item, title: e.target.value })}
          variant="outlined"
          fullWidth
        />
        <TextField
          label="Description (optional)"
          value={item.description}
          onChange={(e) => setItem({ ...item, description: e.target.value })}
          variant="outlined"
          fullWidth
        />
        <MenuBar editor={editor} />
        <EditorContent
          editor={editor}
          className="content-area editor-content"
          onClick={() => editor.chain().focus().run()}
        />
      </Box>
      <Box sx={{ p: 2, borderTop: "1px solid #e0e0e0" }}>
        <Button variant="contained" onClick={handleSaveItem} fullWidth>
          {mode === "edit" ? "Update" : "Publish"}
        </Button>
      </Box>
    </Box>
  );
};

export default FullScreenEditor;
