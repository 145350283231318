import React, { useState, useEffect } from "react";
import { Typography, Box, Card, CardContent, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getAboutInfo, getProjects } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";

function Dashboard() {
  const { token } = useAuth();
  const [aboutInfo, setAboutInfo] = useState({});
  const [projectsCount, setProjectsCount] = useState(0);

  useEffect(() => {
    if (token) {
      fetchAboutInfo();
      fetchProjectsCount();
    }
  }, [token]);

  const fetchAboutInfo = async () => {
    try {
      const response = await getAboutInfo();
      setAboutInfo(response.data);
    } catch (error) {
      console.error("Failed to fetch about info:", error);
    }
  };

  const fetchProjectsCount = async () => {
    try {
      const response = await getProjects();
      setProjectsCount(response.data.length);
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    }
  };

  if (!token) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography>
          Please connect your wallet to access admin features.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Dashboard
      </Typography>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            About Information
          </Typography>
          <Typography variant="body1">Name: {aboutInfo.name}</Typography>
          <Typography variant="body1">Title: {aboutInfo.title}</Typography>
          <Button
            component={Link}
            to="/about"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Edit About Page
          </Button>
        </CardContent>
      </Card>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Projects
          </Typography>
          <Typography variant="body1">
            Total Projects: {projectsCount}
          </Typography>
          <Button
            component={Link}
            to="/projects"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Manage Projects
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Dashboard;
