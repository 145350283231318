import React, { useState } from "react";

const DEFAULT_IMAGE = "/basicImage.webp";

const EducationItem = ({
  id,
  title,
  institution,
  period,
  imageUrl,
  onRemove,
  onSave,
  isLast,
  isEditing,
}) => {
  const [localTitle, setLocalTitle] = useState(title);
  const [localInstitution, setLocalInstitution] = useState(institution);
  const [localPeriod, setLocalPeriod] = useState(period);

  const handleSave = () => {
    onSave(id, {
      title: localTitle,
      institution: localInstitution,
      period: localPeriod,
    });
  };

  return (
    <>
      <div className="flex flex-col items-center gap-1">
        <div className="relative w-10 h-10">
          <img
            src={imageUrl || DEFAULT_IMAGE}
            alt={title}
            className="rounded-full w-full h-full object-cover"
          />
        </div>
        {!isLast && <div className="w-[2px] bg-gray-300 h-full grow"></div>}
      </div>
      <div className="flex flex-1 flex-col py mb-6">
        {isEditing ? (
          <>
            <input
              type="text"
              value={localTitle}
              onChange={(e) => setLocalTitle(e.target.value)}
              className="text-black text-base font-medium leading-normal mb-2 border rounded px-2 py-1"
              placeholder="Degree"
            />
            <input
              type="text"
              value={localInstitution}
              onChange={(e) => setLocalInstitution(e.target.value)}
              className="text-[#6B6B6B] text-base font-normal leading-normal mb-2 border rounded px-2 py-1"
              placeholder="Institution"
            />
            <input
              type="text"
              value={localPeriod}
              onChange={(e) => setLocalPeriod(e.target.value)}
              className="text-[#6B6B6B] text-base font-normal leading-normal mb-2 border rounded px-2 py-1"
              placeholder="Period (e.g. 2017-2021)"
            />
            <div className="flex gap-2 mt-2">
              <button
                onClick={onRemove}
                className="text-red-500 hover:text-red-700 transition-colors duration-200 text-sm"
              >
                Remove
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="text-black text-base font-medium leading-normal">
              {localTitle}
            </p>
            <p className="text-[#6B6B6B] text-base font-normal leading-normal">
              {localInstitution}
            </p>
            <p className="text-[#6B6B6B] text-base font-normal leading-normal">
              {localPeriod}
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default EducationItem;
