import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";

const DEFAULT_IMAGE = "/basicImage.webp";

const ExperienceItem = ({
  id,
  title,
  company,
  period,
  projects,
  imageUrl,
  onEdit,
  onRemove,
  isLast,
  isAuthorized,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const contentRef = useRef(null);

  const checkTextOverflow = () => {
    if (contentRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(contentRef.current).lineHeight
      );
      const height = contentRef.current.clientHeight;
      const lines = Math.floor(height / lineHeight);
      setShouldShowButton(lines > 5);
    }
  };

  useEffect(() => {
    checkTextOverflow();
    window.addEventListener("resize", checkTextOverflow);
    return () => window.removeEventListener("resize", checkTextOverflow);
  }, [projects]);

  const renderProjects = () => {
    if (!projects) return null;

    const sanitizedHtml = DOMPurify.sanitize(projects, {
      ADD_TAGS: [
        "ol",
        "ul",
        "li",
        "p",
        "h1",
        "h2",
        "h3",
        "strong",
        "em",
        "u",
        "s",
        "blockquote",
        "pre",
        "code",
        "img",
      ],
      ADD_ATTR: ["class", "src", "alt", "href", "target"],
    });

    return (
      <div
        ref={contentRef}
        className={`text-[#6B6B6B] text-base font-normal leading-normal content-area ${
          !showMore && shouldShowButton ? "max-h-[120px] overflow-hidden" : ""
        }`}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    );
  };

  return (
    <>
      <div className="flex flex-col items-center gap-1">
        <div className="relative w-10 h-10">
          <img
            src={imageUrl || DEFAULT_IMAGE}
            alt={title}
            className="rounded-full w-full h-full object-cover"
          />
        </div>
        {!isLast && <div className="w-[2px] bg-gray-300 h-full grow"></div>}
      </div>
      <div className="flex flex-1 flex-col py mb-6">
        <p className="text-black text-base font-medium leading-normal">
          {title}
        </p>
        <p className="text-[#6B6B6B] text-base font-normal leading-normal">
          {company}
        </p>
        <p className="text-[#6B6B6B] text-base font-normal leading-normal">
          {period}
        </p>
        {renderProjects()}
        {shouldShowButton && (
          <button
            onClick={() => setShowMore(!showMore)}
            className="text-[#111418] hover:text-[#637588] transition-colors duration-200 text-sm font-bold mt-2"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
        {isAuthorized && (
          <div className="flex gap-2 mt-2">
            <button
              onClick={() =>
                onEdit({ id, title, company, period, projects, imageUrl })
              }
              className="text-[#111418] hover:text-[#637588] transition-colors duration-200 text-sm"
            >
              Edit
            </button>
            <button
              onClick={() => onRemove(id)}
              className="text-[#111418] hover:text-[#637588] transition-colors duration-200 text-sm"
            >
              Remove
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ExperienceItem;
