import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getPlaylist,
  createComment,
  getComments,
  deletePlaylist,
} from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";
import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import "../../styles/ContentStyles.css";

function PlayListDetail() {
  const [playlist, setPlaylist] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, isAuthorized } = useAuth();

  useEffect(() => {
    fetchPlaylist();
    fetchComments();
  }, [id]);

  const fetchPlaylist = async () => {
    setIsLoading(true);
    try {
      const response = await getPlaylist(id);
      setPlaylist(response.data);
    } catch (error) {
      console.error("Failed to fetch playlist:", error);
      setError("Failed to fetch playlist. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchComments = async () => {
    try {
      const response = await getComments(id, "playlist");
      setComments(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      await createComment(id, "playlist", { content: comment });
      setComment("");
      fetchComments();
    } catch (error) {
      console.error("Failed to submit comment:", error);
    }
  };

  const handleDelete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this playlist?"
    );
    if (!confirmed) return;

    try {
      await deletePlaylist(id);
      alert("Playlist deleted successfully.");
      navigate("/playlists");
    } catch (error) {
      console.error("Failed to delete playlist:", error);
      alert("Failed to delete playlist. Please try again.");
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!playlist) return <div>Playlist not found</div>;

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#111418] text-3xl font-bold leading-tight">
            {playlist.title}
          </h1>
          {token && isAuthorized && (
            <div className="flex gap-2">
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#f0f2f4] text-[#111418] text-sm font-bold leading-normal tracking-[0.015em]"
                onClick={() =>
                  navigate(`/editor/edit/${playlist.id}?type=playlist`)
                }
              >
                <span className="truncate">Edit Playlist</span>
              </button>
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-red-600 text-white text-sm font-bold leading-normal tracking-[0.015em]"
                onClick={handleDelete}
              >
                <span className="truncate">Delete Playlist</span>
              </button>
            </div>
          )}
        </div>

        {playlist.image && (
          <div className="w-full h-96 mb-8">
            <img
              className="w-full h-full object-cover rounded-lg shadow-lg"
              src={playlist.image}
              alt={playlist.title}
            />
          </div>
        )}

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">Content</h2>
          <div className="content-area">
            {parse(sanitizeHTML(playlist.content))}
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">Date</h2>
          <p className="text-[#637588] text-base">
            {new Date(playlist.createdAt).toLocaleDateString()}
          </p>
        </div>

        {/* Comments section */}
        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Comments
          </h2>
          {comments && comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.id} className="mb-4 p-4 bg-gray-100 rounded">
                <div className="content-area">
                  {parse(sanitizeHTML(comment.content))}
                </div>
                <p className="text-sm text-gray-500 mt-2">
                  By: {comment.wallet_address} on{" "}
                  {new Date(comment.createdAt).toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p>No comments yet.</p>
          )}
        </div>

        {/* Comment form */}
        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Leave a Comment
          </h2>
          <form onSubmit={handleCommentSubmit}>
            <Editor
              apiKey="bz5ro00mjm6p6u5fgpo6wok5mjhhevw75x973lk7m797620d"
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
              }}
              value={comment}
              onEditorChange={setComment}
            />
            <button
              type="submit"
              className="mt-4 flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#111418] text-white text-sm font-bold leading-normal tracking-[0.015em]"
            >
              Submit Comment
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PlayListDetail;
