import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getArticles, deleteArticle } from "../services/api";
import { useAuth } from "../contexts/AuthContext";

function Articles() {
  const [articles, setArticles] = useState([]);
  const { token, isAuthorized } = useAuth();

  const navigate = useNavigate();
  const DEFAULT_IMAGE = "/articles.webp";
  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await getArticles();
      setArticles(response.data);
    } catch (error) {
      console.error("Failed to fetch articles:", error);
    }
  };

  const handleDelete = async (id, event) => {
    event.stopPropagation();
    if (!token) {
      alert("You need to be logged in to delete an article.");
      return;
    }
    try {
      await deleteArticle(id);
      setArticles(articles.filter((article) => article.id !== id));
    } catch (error) {
      console.error("Failed to delete article:", error);
    }
  };

  const handleArticleClick = (id) => {
    navigate(`/articles/${id}`);
  };

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#111418] text-3xl font-bold leading-tight">
            Articles
          </h1>
          {token && isAuthorized && (
            <button
              className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-black text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em]"
              onClick={() => navigate("/editor/new?type=article")}
            >
              <span className="truncate">새 글쓰기</span>
            </button>
          )}
        </div>
        {articles.map((article) => (
          <div
            className="flex items-center gap-4 bg-white px-4 min-h-[72px] py-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
            key={article.id}
            onClick={() => handleArticleClick(article.id)}
          >
            <div
              className="bg-center bg-no-repeat aspect-square bg-cover rounded-lg size-14 flex-shrink-0"
              style={{
                backgroundImage: `url(${article.image || DEFAULT_IMAGE})`,
              }}
            ></div>
            <div className="flex-grow">
              <p className="text-[#111418] text-base font-medium leading-normal line-clamp-1">
                {article.title}
              </p>
            </div>
            <div className="text-[#637588] text-sm">
              {article.createdAt
                ? new Date(article.createdAt).toLocaleDateString()
                : "날짜 없음"}
            </div>
            {token && (
              <div className="flex gap-2 ml-2">
                <button
                  className="text-[#1980e6] text-sm font-bold"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/editor/edit/${article.id}?type=article`);
                  }}
                >
                  편집
                </button>
                <button
                  className="text-[#e61919] text-sm font-bold"
                  onClick={(e) => handleDelete(article.id, e)}
                >
                  삭제
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
