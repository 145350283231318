import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { searchContent } from "../../services/api";

function SearchResults() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const DEFAULT_IMAGE = "/basicImage.webp";

  useEffect(() => {
    const searchQuery = new URLSearchParams(location.search).get("q");
    if (searchQuery) {
      setLoading(true);
      searchContent(searchQuery)
        .then((response) => {
          setResults(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Search failed:", error);
          setLoading(false);
        });
    }
  }, [location.search]);

  if (loading) return <div className="p-4">Loading...</div>;

  return (
    <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
      <h3 className="text-black text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">
        Search Results
      </h3>
      {results.length === 0 ? (
        <p className="px-4">No results found.</p>
      ) : (
        <div className="grid grid-cols-[repeat(auto-fit,minmax(158px,1fr))] gap-3 p-4">
          {results.map((item) => (
            <div key={item.id} className="flex flex-col gap-3 pb-3">
              <Link to={`/${item.type}/${item.id}`}>
                <div
                  className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl"
                  style={{
                    backgroundImage: `url("${item.image || DEFAULT_IMAGE}")`,
                  }}
                ></div>
              </Link>
              <div>
                <Link
                  to={`/${item.type}/${item.id}`}
                  className="text-black text-base font-medium leading-normal"
                >
                  {item.title}
                </Link>
                <p className="text-[#6B6B6B] text-sm font-normal leading-normal mt-1">
                  {item.description || "No description available"}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResults;
