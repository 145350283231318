import React, { useState } from "react";
import Web3 from "web3";
import { useAuth } from "../contexts/AuthContext";

const WalletConnect = () => {
  const [error, setError] = useState("");
  const { address, login, logout } = useAuth();

  const API_URL = process.env.REACT_APP_API_URL || "https://localhost:8000/api";

  const connectWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(window.ethereum);
        const accounts = await web3.eth.getAccounts();
        const web3Address = accounts[0];

        const response = await fetch(`${API_URL}/auth/verify`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ address: web3Address.toLowerCase() }),
        });

        if (response.ok) {
          const data = await response.json();
          login(web3Address, data.token);
        } else {
          setError("Authentication failed");
        }
      } catch (err) {
        setError("Failed to connect wallet");
        console.error(err);
      }
    } else {
      if (
        window.confirm(
          "MetaMask is not installed. Would you like to install it now?"
        )
      ) {
        window.open("https://metamask.io/download.html", "_blank");
      } else {
        setError("MetaMask is required to connect your wallet.");
      }
    }
  };

  const truncateAddress = (addr) => {
    return `${addr.slice(0, 6)}...${addr.slice(-4)}`;
  };

  return (
    <div className="flex items-center justify-end">
      {address ? (
        <div className="flex items-center space-x-2">
          <p className="text-black text-sm font-medium hidden sm:block">
            Connected: {truncateAddress(address)}
          </p>
          <button
            onClick={logout}
            className="bg-[#EEEEEE] text-black text-sm font-medium px-3 py-2 rounded-xl hover:bg-[#E0E0E0] transition-colors duration-200"
          >
            Disconnect
          </button>
        </div>
      ) : (
        <button
          onClick={connectWallet}
          className="bg-[#EEEEEE] text-black text-sm font-medium px-3 py-2 rounded-xl hover:bg-[#E0E0E0] transition-colors duration-200"
        >
          Connect Wallet
        </button>
      )}
      {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
    </div>
  );
};

export default WalletConnect;
