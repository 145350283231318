import React from "react";

const SkillItem = ({ skill, onEdit, onRemove, isEditing }) => {
  return (
    <div className="flex items-center gap-2 mb-2">
      <div className="flex h-8 shrink-0 items-center justify-center rounded-xl bg-[#EEEEEE] px-4">
        <p className="text-black text-sm font-medium leading-normal">{skill}</p>
      </div>
      {isEditing && (
        <>
          <button
            onClick={onRemove}
            className="text-red-500 hover:text-red-700 transition-colors duration-200 text-sm"
          >
            Remove
          </button>
        </>
      )}
    </div>
  );
};

export default SkillItem;
