import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";
console.log("api url : ", API_URL);
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const FIXED_ADDRESS = process.env.REACT_APP_FIXED_ADDRESS || "default_address";

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getProjects = () => api.get("/projects");
export const getProject = (id) => api.get(`/projects/${id}`);
export const createProject = (data) => {
  console.log("Sending project data:", data); // 디버깅을 위해 추가
  return api.post("/projects", data);
};
export const updateProject = (id, data) => api.put(`/projects/${id}`, data);
export const deleteProject = (id) => api.delete(`/projects/${id}`);

export const getArticles = () => api.get("/articles");
export const getArticle = (id) => api.get(`/articles/${id}`);
export const createArticle = (data) => api.post("/articles", data);
export const updateArticle = (id, data) => api.put(`/articles/${id}`, data);
export const deleteArticle = (id) => api.delete(`/articles/${id}`);

export const getAboutInfo = () => api.get(`/about/${FIXED_ADDRESS}`);
export const updateDescription = (description) =>
  api.put(`/about/${FIXED_ADDRESS}/description`, { description });

export const addExperience = (data) =>
  api.post(`/about/${FIXED_ADDRESS}/experience`, data);
export const updateExperience = (id, data) =>
  api.put(`/about/experience/${id}`, data);
export const deleteExperience = (id) => api.delete(`/about/experience/${id}`);

export const addEducation = (data) =>
  api.post(`/about/${FIXED_ADDRESS}/education`, data);
export const updateEducation = (id, data) =>
  api.put(`/about/${FIXED_ADDRESS}/education/${id}`, data);
export const deleteEducation = (id) =>
  api.delete(`/about/${FIXED_ADDRESS}/education/${id}`);

export const addSkill = (data) =>
  api.post(`/about/${FIXED_ADDRESS}/skill`, data);
export const updateSkill = (id, data) => api.put(`/about/skill/${id}`, data);
export const deleteSkill = (id) => api.delete(`/about/skill/${id}`);

export const verifyAddress = (address) => api.post("/auth/verify", { address });
export const uploadImage = async (formData) => {
  console.log("uploadImage api post 요청");
  try {
    const response = await api.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Upload successful: ", response.data);
    return response;
  } catch (error) {
    console.error("Upload failed: ", error);
    throw error;
  }
};

export const getComments = (id, type) =>
  api.get(`/comments/${id}?type=${type}`);
export const createComment = (id, type, data) =>
  api.post(`/comments/${id}?type=${type}`, data);
export const deleteComment = (id) => api.delete(`/comments/${id}`);

export const getVlogs = () => api.get("/vlogs");
export const getVlog = (id) => api.get(`/vlogs/${id}`);
export const createVlog = (data) => api.post("/vlogs", data);
export const updateVlog = (id, data) => api.put(`/vlogs/${id}`, data);
export const deleteVlog = (id) => api.delete(`/vlogs/${id}`);

export const getTechs = () => api.get("/techs");
export const getTech = (id) => api.get(`/techs/${id}`);
export const createTech = (data) => api.post("/techs", data);
export const updateTech = (id, data) => api.put(`/techs/${id}`, data);
export const deleteTech = (id) => api.delete(`/techs/${id}`);

export const getPlaylists = () => api.get("/playlists");
export const getPlaylist = (id) => api.get(`/playlists/${id}`);
export const createPlaylist = (data) => api.post("/playlists", data);
export const updatePlaylist = (id, data) => api.put(`/playlists/${id}`, data);
export const deletePlaylist = (id) => api.delete(`/playlists/${id}`);

export const getVisitorCount = () => api.get("/visitorCount");
export const incrementVisitorCount = () => api.post("/visitorCount/increment");

export const searchContent = (query) =>
  api.get(`/search?q=${encodeURIComponent(query)}`);

export const sendContactMessage = (data) => api.post("/contact", data);
export const sendVerificationCode = (email) =>
  api.post("/contact/send-verification", { email });
// verifyCode 함수는 더 이상 필요하지 않으므로 제거합니다.
export default api;
