import React, { createContext, useState, useContext, useEffect } from "react";
import { verifyAddress, getAboutInfo } from "../services/api";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [address, setAddress] = useState(localStorage.getItem("address"));
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const checkAuthorization = async () => {
      console.log("Checking authorization for address:", address);
      if (address) {
        try {
          const response = await verifyAddress(address);
          console.log("Verification response:", response.data);
          setIsAuthorized(response.data.isAuthorized);
          console.log("Is authorized:", response.data.isAuthorized);

          if (response.data.isAuthorized) {
            if (response.data.created) {
              await fetchInitialAboutInfo();
            }
            setToken(response.data.token);
            localStorage.setItem("token", response.data.token);
          }
        } catch (error) {
          console.error("Failed to verify address:", error);
        }
      }
    };

    checkAuthorization();
  }, [address]);

  const fetchInitialAboutInfo = async () => {
    try {
      await getAboutInfo();
      console.log("Initial About info fetched");
    } catch (error) {
      console.error("Failed to fetch initial about info:", error);
    }
  };

  const login = async (newAddress, newToken) => {
    console.log("Login called with address:", newAddress);
    try {
      setToken(newToken);
      setAddress(newAddress);
      setIsAuthorized(true);
      localStorage.setItem("token", newToken);
      localStorage.setItem("address", newAddress);

      await fetchInitialAboutInfo();
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const logout = () => {
    console.log("Logout called");
    setToken(null);
    setAddress(null);
    setIsAuthorized(false);
    localStorage.removeItem("token");
    localStorage.removeItem("address");
  };

  console.log("Current auth state:", { token, address, isAuthorized });

  return (
    <AuthContext.Provider
      value={{ token, address, isAuthorized, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
