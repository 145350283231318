import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getArticles,
  getVisitorCount,
  incrementVisitorCount,
} from "../services/api";

function SideContent() {
  const [latestArticles, setLatestArticles] = useState([]);
  const [visitorCount, setVisitorCount] = useState(0);
  const [dailyTotalCount, setDailyTotalCount] = useState(0);
  const [dailyCount, setDailyCount] = useState(0);
  const [visitorIp, setVisitorIp] = useState("");
  const DEFAULT_IMAGE = "/basicImage.webp";

  useEffect(() => {
    fetchArticles();
    checkAndIncrementVisitorCount();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await getArticles();
      setLatestArticles(response.data.slice(0, 3));
    } catch (error) {
      console.error("Failed to fetch articles:", error);
    }
  };

  const checkAndIncrementVisitorCount = async () => {
    const lastVisit = sessionStorage.getItem("lastVisit");
    const now = new Date().getTime();

    console.log("Last visit:", lastVisit);
    console.log("Current time:", now);

    if (!lastVisit || now - parseInt(lastVisit) > 30 * 60 * 1000) {
      try {
        const response = await incrementVisitorCount();
        console.log("Increment visitor count response:", response.data);
        setVisitorCount(response.data.totalCount);
        setDailyTotalCount(response.data.dailyTotalCount);
        setDailyCount(response.data.dailyCount);
        setVisitorIp(response.data.ip);
        sessionStorage.setItem("lastVisit", now.toString());
      } catch (error) {
        console.error("Failed to increment visitor count:", error);
      }
    } else {
      try {
        const response = await getVisitorCount();
        console.log("Get visitor count response:", response.data);
        setVisitorCount(response.data.totalCount);
        setDailyTotalCount(response.data.dailyTotalCount);
        setDailyCount(response.data.dailyCount);
      } catch (error) {
        console.error("Failed to fetch visitor count:", error);
      }
    }
  };

  return (
    <div className="p-2 border-t border-gray-200">
      <h2 className="text-lg font-semibold mb-4">Latest Articles</h2>
      {latestArticles.map((article) => (
        <Link
          key={article.id}
          to={`/articles/${article.id}`}
          className="flex items-center gap-4 mb-4"
        >
          <div
            className="bg-center bg-no-repeat aspect-square bg-cover rounded-lg w-12 h-12"
            style={{
              backgroundImage: `url("${article.image || DEFAULT_IMAGE}")`,
            }}
          ></div>
          <p className="text-sm font-medium leading-tight line-clamp-2">
            {article.title}
          </p>
        </Link>
      ))}
      <div className="mt-4">
        <h2 className="text-lg font-semibold mb-2">Visitor Count</h2>
        <p className="text-2xl font-bold">Total: {visitorCount}</p>
        <p className="text-md">Today's total: {dailyTotalCount}</p>
        <p className="text-sm">Your visits today: {dailyCount}</p>
      </div>
    </div>
  );
}

export default SideContent;
