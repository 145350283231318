import React, { useState, useEffect } from "react";
import {
  getAboutInfo,
  addExperience,
  updateExperience,
  deleteExperience,
  addEducation,
  updateEducation,
  deleteEducation,
  addSkill,
  updateSkill,
  deleteSkill,
} from "../../services/api";
import ExperienceItem from "./ExperienceItem";
import EducationItem from "./EducationItem";
import SkillItem from "./SkillItem";
import { useAuth } from "../../contexts/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { Box, IconButton, Tooltip, TextField, Button } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CodeIcon from "@mui/icons-material/Code";
import ImageIcon from "@mui/icons-material/Image";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import "../../styles/ContentStyles.css";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="editor-menu-bar">
      <Tooltip title="Bold">
        <IconButton onClick={() => editor.chain().focus().toggleBold().run()}>
          <FormatBoldIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Italic">
        <IconButton onClick={() => editor.chain().focus().toggleItalic().run()}>
          <FormatItalicIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Bullet List">
        <IconButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <FormatListBulletedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Numbered List">
        <IconButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <FormatListNumberedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Code">
        <IconButton onClick={() => editor.chain().focus().toggleCode().run()}>
          <CodeIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Image">
        <IconButton
          onClick={() => {
            const url = window.prompt("Enter the URL of the image:");
            if (url) {
              editor.chain().focus().setImage({ src: url }).run();
            }
          }}
        >
          <ImageIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align Left">
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
        >
          <FormatAlignLeftIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align Center">
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
        >
          <FormatAlignCenterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align Right">
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
        >
          <FormatAlignRightIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Align Justify">
        <IconButton
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
        >
          <FormatAlignJustifyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const About = () => {
  const [aboutInfo, setAboutInfo] = useState({
    description: "",
    experiences: [],
    education: [],
    skills: [],
  });
  const [isEditingExperience, setIsEditingExperience] = useState(false);
  const [isEditingSkills, setIsEditingSkills] = useState(false);
  const { address, isAuthorized } = useAuth();
  const [editingExperience, setEditingExperience] = useState(null);
  const [newSkill, setNewSkill] = useState({
    id: "",
    skill: "",
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Image,
      Link,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Color,
      TextStyle,
    ],
    content: editingExperience
      ? editingExperience.projects
      : "<p>Start writing here...</p>",
    onUpdate: ({ editor }) => {
      if (editingExperience) {
        setEditingExperience((prev) => ({
          ...prev,
          projects: editor.getHTML(),
        }));
      }
    },
  });

  useEffect(() => {
    fetchAboutInfo();
  }, []);

  useEffect(() => {
    if (editor && editingExperience) {
      editor.commands.setContent(editingExperience.projects);
    }
  }, [editingExperience, editor]);

  const fetchAboutInfo = async () => {
    try {
      const response = await getAboutInfo();
      console.log("Fetched about info:", response.data);

      const sortedData = {
        ...response.data,
        experiences: response.data.experiences.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
        education: response.data.education.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
        skills: response.data.skills.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        ),
      };

      setAboutInfo(sortedData);
    } catch (error) {
      console.error("Failed to fetch about info:", error);
    }
  };

  const handleEditExperience = (experience) => {
    setEditingExperience(experience);
    setIsEditingExperience(true);
  };

  const handleSaveExperience = async () => {
    try {
      if (editingExperience.id) {
        await updateExperience(editingExperience.id, editingExperience);
      } else {
        const response = await addExperience({
          ...editingExperience,
          id: uuidv4(),
        });
        setAboutInfo((prevInfo) => ({
          ...prevInfo,
          experiences: [...prevInfo.experiences, response.data].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
        }));
      }
      setEditingExperience(null);
      setIsEditingExperience(false);
      fetchAboutInfo();
    } catch (error) {
      console.error("Failed to save experience:", error);
    }
  };

  const handleRemoveExperience = async (id) => {
    try {
      await deleteExperience(id);
      setAboutInfo((prevInfo) => ({
        ...prevInfo,
        experiences: prevInfo.experiences.filter((exp) => exp.id !== id),
      }));
    } catch (error) {
      console.error("Failed to delete experience:", error);
    }
  };

  const handleSaveEducation = async (id, updatedEducation) => {
    try {
      if (id) {
        await updateEducation(id, updatedEducation);
      } else {
        const response = await addEducation({
          ...updatedEducation,
          id: uuidv4(),
        });
        setAboutInfo((prevInfo) => ({
          ...prevInfo,
          education: [...prevInfo.education, response.data].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          ),
        }));
      }
      fetchAboutInfo();
    } catch (error) {
      console.error("Failed to save education:", error);
    }
  };

  const handleRemoveEducation = async (id) => {
    try {
      await deleteEducation(id);
      setAboutInfo((prevInfo) => ({
        ...prevInfo,
        education: prevInfo.education.filter((edu) => edu.id !== id),
      }));
    } catch (error) {
      console.error("Failed to delete education:", error);
    }
  };

  const handleAddOrUpdateSkill = async () => {
    try {
      if (newSkill.id) {
        await updateSkill(newSkill.id, newSkill);
      } else {
        const response = await addSkill({
          skill: newSkill.skill,
          id: uuidv4(),
        });
        setAboutInfo((prevInfo) => {
          const updatedSkills = [...prevInfo.skills, response.data].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          return {
            ...prevInfo,
            skills: updatedSkills,
          };
        });
      }
      setNewSkill({ id: "", skill: "" });
      setIsEditingSkills(false);
      fetchAboutInfo();
    } catch (error) {
      console.error("Failed to add/update skill:", error);
    }
  };

  const handleRemoveSkill = async (id) => {
    try {
      await deleteSkill(id);
      setAboutInfo((prevInfo) => ({
        ...prevInfo,
        skills: prevInfo.skills.filter((skill) => skill.id !== id),
      }));
    } catch (error) {
      console.error("Failed to delete skill:", error);
    }
  };

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#111418] text-3xl font-bold leading-tight">
            About me
          </h1>
        </div>

        <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] mb-4">
          Professional Experience
        </h2>
        <div className="grid grid-cols-[40px_1fr] gap-x-2 mb-4">
          {aboutInfo.experiences
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((experience, index) => (
              <ExperienceItem
                key={experience.id}
                {...experience}
                onEdit={handleEditExperience}
                onRemove={handleRemoveExperience}
                isAuthorized={isAuthorized}
                isLast={index === aboutInfo.experiences.length - 1}
              />
            ))}
        </div>
        {isAuthorized && (
          <>
            {isEditingExperience && (
              <div className="mb-4 p-4 border rounded-xl">
                <h3 className="text-lg font-semibold mb-2">
                  {editingExperience.id
                    ? "Edit Experience"
                    : "Add New Experience"}
                </h3>
                <TextField
                  label="Job Title"
                  value={editingExperience.title}
                  onChange={(e) =>
                    setEditingExperience((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Company"
                  value={editingExperience.company}
                  onChange={(e) =>
                    setEditingExperience((prev) => ({
                      ...prev,
                      company: e.target.value,
                    }))
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Period"
                  value={editingExperience.period}
                  onChange={(e) =>
                    setEditingExperience((prev) => ({
                      ...prev,
                      period: e.target.value,
                    }))
                  }
                  fullWidth
                  margin="normal"
                />
                <Box sx={{ my: 2 }}>
                  <MenuBar editor={editor} />
                  <EditorContent editor={editor} className="editor-content" />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <Button
                    onClick={() => setIsEditingExperience(false)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button onClick={handleSaveExperience} variant="contained">
                    Save
                  </Button>
                </Box>
              </div>
            )}
            {!isEditingExperience && (
              <button
                className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 mb-4 bg-black text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em] hover:bg-gray-800 transition-colors duration-200"
                onClick={() => {
                  setEditingExperience({
                    id: "",
                    title: "",
                    company: "",
                    period: "",
                    projects: "<p>Start writing here...</p>",
                    imageUrl: "",
                  });
                  setIsEditingExperience(true);
                }}
              >
                <span className="truncate">Add New Experience</span>
              </button>
            )}
          </>
        )}

        <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] mb-4">
          Education
        </h2>
        <div className="grid grid-cols-[40px_1fr] gap-x-2 mb-4">
          {aboutInfo.education
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((edu, index) => (
              <EducationItem
                key={edu.id}
                {...edu}
                onSave={handleSaveEducation}
                onRemove={handleRemoveEducation}
                isEditing={isAuthorized}
                isLast={index === aboutInfo.education.length - 1}
              />
            ))}
        </div>
        {isAuthorized && (
          <button
            className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 mb-4 bg-black text-[#FFFFFF] text-sm font-bold leading-normal tracking-[0.015em] hover:bg-gray-800 transition-colors duration-200"
            onClick={() =>
              handleSaveEducation(null, {
                title: "",
                institution: "",
                period: "",
              })
            }
          >
            <span className="truncate">Add New Education</span>
          </button>
        )}

        <h2 className="text-black text-[22px] font-bold leading-tight tracking-[-0.015em] mb-4">
          Skills & Technologies
        </h2>
        <div className="flex flex-wrap gap-2 mb-4">
          {aboutInfo.skills
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((skillObj) => (
              <SkillItem
                key={skillObj.id}
                skill={skillObj.skill}
                onRemove={() => handleRemoveSkill(skillObj.id)}
                isEditing={isAuthorized}
              />
            ))}
        </div>
        {isAuthorized && (
          <div className="mb-4 p-4 border rounded-xl">
            <h3 className="text-lg font-semibold mb-2">Add New Skill</h3>
            <TextField
              label="Skill"
              name="skill"
              value={newSkill.skill}
              onChange={(e) =>
                setNewSkill({ ...newSkill, skill: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <Button
              onClick={handleAddOrUpdateSkill}
              variant="contained"
              className="mt-2"
            >
              Add Skill
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
