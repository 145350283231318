// import React, { useState, useEffect } from "react";
// import { sendContactMessage, sendVerificationCode } from "../services/api";
// import { auth } from "../config/firebase";
// import {
//   isSignInWithEmailLink,
//   signInWithEmailLink,
//   send,
// } from "firebase/auth";

// function Contact() {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phone, setPhone] = useState("");
//   const [message, setMessage] = useState("");
//   const [status, setStatus] = useState("");
//   const [isVerified, setIsVerified] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSendingCode, setIsSendingCode] = useState(false);

//   useEffect(() => {
//     // 로컬 스토리지에서 저장된 데이터 복원
//     const savedData = JSON.parse(
//       localStorage.getItem("contactFormData") || "{}"
//     );
//     setName(savedData.name || "");
//     setEmail(savedData.email || "");
//     setPhone(savedData.phone || "");
//     setMessage(savedData.message || "");

//     const verifyEmail = async () => {
//       const urlParams = new URLSearchParams(window.location.search);
//       const verificationComplete = urlParams.get("verificationComplete");

//       if (verificationComplete === "true") {
//         const email = window.localStorage.getItem("emailForSignIn");
//         if (email) {
//           setIsVerified(true);
//           setEmail(email);
//           setStatus("이메일이 성공적으로 인증되었습니다!");
//           window.localStorage.removeItem("emailForSignIn");
//           // 쿼리 파라미터 제거
//           window.history.replaceState(
//             {},
//             document.title,
//             window.location.pathname
//           );
//         }
//       } else if (isSignInWithEmailLink(auth, window.location.href)) {
//         let email = window.localStorage.getItem("emailForSignIn");
//         if (!email) {
//           email = window.prompt("Please provide your email for confirmation");
//         }
//         try {
//           await signInWithEmailLink(auth, email, window.location.href);
//           // 인증 완료 후 현재 페이지로 리다이렉트
//           const redirectUrl = `${window.location.origin}${window.location.pathname}?verificationComplete=true`;
//           window.location.href = redirectUrl;
//         } catch (error) {
//           console.error("인증 오류:", error);
//           setStatus("이메일 인증에 실패했습니다. 다시 시도해주세요.");
//         }
//       }
//     };
//     verifyEmail();
//   }, []);

//   // 입력 데이터 변경 시 로컬 스토리지에 저장
//   useEffect(() => {
//     localStorage.setItem(
//       "contactFormData",
//       JSON.stringify({ name, email, phone, message })
//     );
//   }, [name, email, phone, message]);

//   const handleSendVerificationCode = async () => {
//     if (!email) {
//       setStatus("이메일 주소를 입력해주세요.");
//       return;
//     }
//     setIsSendingCode(true);
//     try {
//       // 현재 페이지 URL을 Firebase 인증 완료 후 리다이렉트 URL로 설정
//       const redirectUrl = `${window.location.origin}${window.location.pathname}`;
//       await sendVerificationCode(email, redirectUrl);
//       window.localStorage.setItem("emailForSignIn", email);
//       setStatus(
//         "인증 이메일이 전송되었습니다. 이메일을 확인하고 링크를 클릭하여 인증해주세요."
//       );
//     } catch (error) {
//       console.error("인증 이메일 전송 실패:", error);
//       setStatus("인증 이메일 전송에 실패했습니다. 다시 시도해주세요.");
//     } finally {
//       setIsSendingCode(false);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!isVerified) {
//       setStatus("메시지를 보내기 전에 이메일 인증을 완료해주세요.");
//       return;
//     }
//     setIsLoading(true);
//     try {
//       await sendContactMessage({ name, email, phone, message });
//       setStatus("메시지가 성공적으로 전송되었습니다!");
//       setName("");
//       setPhone("");
//       setMessage("");
//       // 이메일과 인증 상태는 유지
//     } catch (error) {
//       console.error("메시지 전송 실패:", error);
//       setStatus("메시지 전송에 실패했습니다. 다시 시도해주세요.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="container mx-auto px-4 py-8">
//       <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <div>
//           <label
//             htmlFor="name"
//             className="block text-sm font-medium text-[#637588]"
//           >
//             Name
//           </label>
//           <input
//             type="text"
//             id="name"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//             required
//             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="email"
//             className="block text-sm font-medium text-[#637588]"
//           >
//             Email
//           </label>
//           <input
//             type="email"
//             id="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="phone"
//             className="block text-sm font-medium text-[#637588]"
//           >
//             Phone
//           </label>
//           <input
//             type="tel"
//             id="phone"
//             value={phone}
//             onChange={(e) => setPhone(e.target.value)}
//             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
//           />
//         </div>
//         <div>
//           <label
//             htmlFor="message"
//             className="block text-sm font-medium text-[#637588]"
//           >
//             Message
//           </label>
//           <textarea
//             id="message"
//             value={message}
//             onChange={(e) => setMessage(e.target.value)}
//             required
//             rows={4}
//             className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
//           />
//         </div>
//         <div>
//           <button
//             type="button"
//             onClick={handleSendVerificationCode}
//             disabled={isSendingCode || isVerified}
//             className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
//           >
//             {isSendingCode ? "전송 중..." : "인증 이메일 전송"}
//           </button>
//         </div>
//         {isVerified && (
//           <p className="text-green-600 text-sm">이메일이 인증되었습니다.</p>
//         )}
//         <button
//           type="submit"
//           disabled={!isVerified || isLoading}
//           className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-50"
//         >
//           {isLoading ? "전송 중..." : "메시지 보내기"}
//         </button>
//       </form>
//       {status && <p className="mt-4 text-sm text-gray-600">{status}</p>}
//     </div>
//   );
// }

// export default Contact;

import React, { useState, useEffect } from "react";
import { sendContactMessage } from "../services/api";
import { auth } from "../config/firebase";
import { sendEmailVerification } from "firebase/auth";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  // BroadcastChannel 인스턴스 생성
  const channel = new BroadcastChannel("auth-channel");

  useEffect(() => {
    // 로컬 스토리지에서 저장된 데이터 복원
    const savedData = JSON.parse(
      localStorage.getItem("contactFormData") || "{}"
    );
    setName(savedData.name || "");
    setEmail(savedData.email || "");
    setPhone(savedData.phone || "");
    setMessage(savedData.message || "");

    // 로컬 스토리지에서 인증 상태 복원
    const verifiedStatus = localStorage.getItem("isVerified") === "true";
    setIsVerified(verifiedStatus);

    // 사용자 인증 상태 확인 및 이메일 인증 상태 업데이트
    const checkVerificationStatus = async () => {
      const user = auth.currentUser;
      if (user && user.emailVerified) {
        setIsVerified(true);
        localStorage.setItem("isVerified", "true");
        // 다른 탭에 인증 상태 전송
        channel.postMessage({ isVerified: true });
      }
    };
    checkVerificationStatus();

    // BroadcastChannel로부터 인증 상태 업데이트 수신
    channel.onmessage = (event) => {
      if (event.data.isVerified) {
        setIsVerified(true);
        localStorage.setItem("isVerified", "true");
      }
    };

    // 컴포넌트 언마운트 시 BroadcastChannel 닫기
    return () => {
      channel.close();
    };
  }, []);

  // 입력 데이터 변경 시 로컬 스토리지에 저장
  useEffect(() => {
    localStorage.setItem(
      "contactFormData",
      JSON.stringify({ name, email, phone, message })
    );
  }, [name, email, phone, message]);

  const handleSendVerificationCode = async () => {
    if (!email) {
      setStatus("이메일 주소를 입력해주세요.");
      return;
    }
    setIsSendingCode(true);
    try {
      // 사용자가 로그인한 상태에서 이메일 인증 메일을 보냄
      await sendEmailVerification(auth.currentUser);
      setStatus(
        "인증 이메일이 전송되었습니다. 이메일을 확인하고 링크를 클릭하여 인증해주세요."
      );
    } catch (error) {
      console.error("인증 이메일 전송 실패:", error);
      setStatus("인증 이메일 전송에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isVerified) {
      setStatus("메시지를 보내기 전에 이메일 인증을 완료해주세요.");
      return;
    }
    setIsLoading(true);
    try {
      await sendContactMessage({ name, email, phone, message });
      setStatus("메시지가 성공적으로 전송되었습니다!");
      setName("");
      setPhone("");
      setMessage("");
      // 이메일과 인증 상태는 유지
    } catch (error) {
      console.error("메시지 전송 실패:", error);
      setStatus("메시지 전송에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-[#637588]"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-[#637588]"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-[#637588]"
          >
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-[#637588]"
          >
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            rows={4}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm"
          />
        </div>
        <div>
          <button
            type="button"
            onClick={handleSendVerificationCode}
            disabled={isSendingCode || isVerified}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
          >
            {isSendingCode ? "전송 중..." : "인증 이메일 전송"}
          </button>
        </div>
        {isVerified && (
          <p className="text-green-600 text-sm">이메일이 인증되었습니다.</p>
        )}
        <button
          type="submit"
          disabled={!isVerified || isLoading}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black disabled:opacity-50"
        >
          {isLoading ? "전송 중..." : "메시지 보내기"}
        </button>
      </form>
      {status && <p className="mt-4 text-sm text-gray-600">{status}</p>}
    </div>
  );
}

export default Contact;
