import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getArticle, createComment, getComments } from "../services/api";
import { useAuth } from "../contexts/AuthContext";
import { Editor } from "@tinymce/tinymce-react";
import DOMPurify from "dompurify";

function ArticleDetail() {
  const [article, setArticle] = useState(null);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, isAuthorized } = useAuth();

  useEffect(() => {
    fetchArticle();
    fetchComments();
  }, [id]);

  const fetchArticle = async () => {
    try {
      const response = await getArticle(id);
      setArticle(response.data);
    } catch (error) {
      console.error("Failed to fetch article:", error);
    }
  };
  const sanitizeHTML = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  const fetchComments = async () => {
    try {
      const response = await getComments(id, "article");
      setComments(response.data);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      await createComment(id, "article", { content: comment });
      setComment("");
      fetchComments(); // 댓글 목록 새로고침
    } catch (error) {
      console.error("Failed to submit comment:", error);
    }
  };

  if (!article) return <div>Loading...</div>;

  return (
    <div className="px-6 py-5">
      <div className="max-w-[960px] mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#111418] text-3xl font-bold leading-tight">
            {article.title}
          </h1>
          {token && isAuthorized && (
            <button
              className="flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#f0f2f4] text-[#111418] text-sm font-bold leading-normal tracking-[0.015em]"
              onClick={() =>
                navigate(`/editor/edit/${article.id}?type=article`)
              }
            >
              <span className="truncate">Edit Article</span>
            </button>
          )}
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">
            Description
          </h2>
          <p className="text-[#637588] text-base">{article.description}</p>
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">Content</h2>
          <div
            className="text-[#637588] text-base"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-2">Date</h2>
          <p className="text-[#637588] text-base">
            {new Date(article.createdAt).toLocaleDateString()}
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Comments
          </h2>
          {comments.map((comment) => (
            <div key={comment.id} className="mb-4 p-4 bg-gray-100 rounded">
              <div dangerouslySetInnerHTML={sanitizeHTML(comment.content)} />
              <p className="text-sm text-gray-500 mt-2">
                By: {comment.wallet_address} on{" "}
                {new Date(comment.createdAt).toLocaleString()}
              </p>
            </div>
          ))}
        </div>

        <div className="mb-8">
          <h2 className="text-[#111418] text-xl font-semibold mb-4">
            Leave a Comment
          </h2>
          <form onSubmit={handleCommentSubmit}>
            <Editor
              apiKey="bz5ro00mjm6p6u5fgpo6wok5mjhhevw75x973lk7m797620d"
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
              }}
              value={comment}
              onEditorChange={setComment}
            />
            <button
              type="submit"
              className="mt-4 flex min-w-[84px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#111418] text-white text-sm font-bold leading-normal tracking-[0.015em]"
            >
              Submit Comment
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ArticleDetail;
